import React, { Component } from 'react';
import logo from './images/splashIcon_3x.png';
import newLogo from './images/looksy-06.png';
import vectorGraphic from './images/looksy-04.jpg';
import appStore from './images/app-store.png';
import playStore from './images/play-store.png';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={newLogo} className="App-logo" alt="logo"  />
          <img src={vectorGraphic} className="App-hero" alt="logo"  />
          <p>
            The camera app that gets your pets attention for the perfect snap
          </p>
          <div className="d-flex">
            <a href="https://itunes.apple.com/us/app/lookeer/id1448059194">
              <img src={appStore} className="App-download" style={{paddingRight: 5}} alt="logo"  />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.lookhereapp">
              <img src={playStore} className="App-download" style={{paddingLeft: 5}} alt="logo"  />
            </a>
          </div>
          <div style={{alignText:'center', paddingTop: '80px', marginTop: '80px'}} >
            <a
              className="App-link"
              href="http://lookeerapp.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
              style={{color: 'white'}} >
              Privacy Policy
            </a>
            <p>
              &copy;	Dakota Brown 2019
            </p>
          
          </div>
        </header>
      </div>
    );
  }
}

export default App;
